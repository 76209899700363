<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal, BFormCheckbox, BFormFile } from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BModal, BFormCheckbox, BFormFile,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],

      addModal: false,
      addObject: {
        modelName: '',
        modelVisible: false,
        modelImage: {},
        modelAcceleration: '',
        modelBrake: 1,
        modelGrip: '',
        modelTopSpeed: '',
        modelStockPrice: '',
        modelEnum: '',
      },
      addImageView: '',

      editModal: false,
      editObject: {
        modelName: '',
        modelVisible: false,
        modelImage: {},
        modelAcceleration: '',
        modelBrake: '',
        modelGrip: '',
        modelTopSpeed: '',
        modelStockPrice: '',
        modelEnum: '',
      },
      editImageView: '',

      filterName: '',
      viewMedia: '',
      mediaModal: false,
    }
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {

      // Clear Variables..
      this.addModal = false
      this.editModal = false
      this.addObject = {
        modelName: '',
        modelVisible: false,
        modelImage: {},
        modelAcceleration: '',
        modelBrake: 1,
        modelGrip: '',
        modelTopSpeed: '',
        modelStockPrice: 1,
        modelEnum: '',
      }

      this.filterFirstname = ''
      this.filterLastname = ''

      this.addImageView = ''
      this.editImageView = ''

      // Get List
      if (this.$route.params.brandId) {
        await this.$http.get(`/features/brand/one/${this.$route.params.brandId}`)
          .then(response => {
            if (response.data) {
              this.pageArrayStock = this.pageArray = response.data
            }
          }).catch(() => {
            alert('ERROR! PLEASE CONTACT TO DEVELOPER')
          })
      } else {
        this.$router.push(-1)
      }
    },
    async tableInteractive(item, index, type) {
      if (type === 'edit') {
        this.editObject = structuredClone(item)
        this.editModal = true
      }

      if (type === 'active') {
        this.$swal.fire({
          title: item.brandVisible ? 'Modeli Pasif Yapıyorsunuz' : 'Modeeli Aktif Yapıyorsunuz',
          text: 'Modeel görünürlüğünü değiştiriyorsunuz.',
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: 'İptal Et',
          cancelButtonColor: '#B72424',
          confirmButtonText: 'Devam Et',
          confirmButtonColor: '#26B724'
        }).then(async response => {
          if (response.isConfirmed) {
            await this.$http.put('features/model/visible', { modelId: item.modelId, modelVisible: !item.modelVisible })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.mountedData()
              }).catch(error => {
                let errorMessage = ''
                switch (error.response.data) {
                  case 'CannotDeleteYourself':
                    errorMessage = 'Cannot Delete Yourself';
                    break;
                  case 'PermissionError':
                    errorMessage = 'Permission Error!';
                    break;
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMessage,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
      }
    },
    async saveData(type) {
      if (type === 'create') {

        const sendObject = {
          modelBrandId: this.$route.params.brandId,
          modelName: this.addObject.modelName,
          modelVisible: this.addObject.modelVisible,
          modelAcceleration: parseInt(this.addObject.modelAcceleration),
          modelBrake: parseInt(this.addObject.modelBrake),
          modelGrip: parseInt(this.addObject.modelGrip),
          modelTopSpeed: parseInt(this.addObject.modelTopSpeed),
          modelStockPrice: this.addObject.modelStockPrice,
          modelEnum: this.addObject.modelEnum
        }

        await this.$http.post('features/model/create', sendObject)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'BadValidation':
                errorMessage = 'Model Name To Short';
                break;
              case 'EnumIsValid':
                errorMessage = 'ENUM Is Valid!';
                break;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }

      if (type === 'update') {

        const sendObject = {
          modelId: this.editObject.modelId,
          modelName: this.editObject.modelName,
          modelAcceleration: parseInt(this.editObject.modelAcceleration),
          modelBrake: parseInt(this.editObject.modelBrake),
          modelGrip: parseInt(this.editObject.modelGrip),
          modelTopSpeed: parseInt(this.editObject.modelTopSpeed),
          modelStockPrice: this.editObject.modelStockPrice.toString(),
        }

        await this.$http.put('features/model/update', sendObject)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          }).catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'AdminEmailIsRegistered':
                errorMessage = 'Admin Email is Valid';
                break;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.modelName.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase()))
    },
    imagePreview(event, type) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        if (type === 'add') {
          this.addImageView = fileReader.result
        } else {
          this.editImageView = fileReader.result
        }
      }
      fileReader.readAsDataURL(event.target.files[0])
      if (type === 'add') {
        this.addObject.modelImage = event.target.files[0]
      } else {
        this.editObject.modelImage = event.target.files[0]
      }
    },
    deleteImage(type) {
      if (type === 'add') {
        this.addImageView = ''
        this.addObject.modelImage = {}
      } else {
        this.editImageView = ''
        this.editObject.modelImage = {}
      }
    },
    openMedia(mediaUrl) {
      this.viewMedia = mediaUrl
      this.mediaModal = true
    },
  },
  watch: {
    filterName: {
      handler() {
        this.filterNow()
      }
    },
  }
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Car Models ({{ pageArrayStock.length }})</a>
      <button class="tb-button bg-primary ml-auto" @click="addModal = true">
        <a class="text-white">Create Car Model</a>
      </button>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly></th>
            <!--
          <th style="width: 15%"><input type="text" class="form-control" placeholder="Model Image" disabled readonly></th>
          -->
            <th style="width: 70%"><input type="text" class="form-control" placeholder="Model Name"
                v-model="filterName">
            </th>
            <th style="width: 25%"><input type="text" class="form-control" placeholder="Options" disabled readonly></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <!--
          <td>
            <img v-if="item.modelImage" :src="item.modelImage" class="w-100 h-120-px tb-img-cover tb-border-radius-0-5-rem tb-hover-pointer" alt="" @click="openMedia(item.modelImage)">
            <div v-else class="w-100 h-120-px bg-primary tb-flex-full tb-border-radius-0-5-rem tb-hover-pointer">
              <feather-icon icon="ImageIcon" size="32" class="text-white" />
            </div>
          </td>
          -->
            <td><a>{{ item.modelName }}</a></td>
            <td>
              <button class="tb-button bg-success" @click="$router.push(`/carPartTypes/${item.modelId}`)">
                <span class="text-white">Parts</span>
              </button>
              <button class="tb-button bg-primary tb-ml-5-px" @click="tableInteractive(item, index, 'edit')">
                <span class="text-white">Edit</span>
              </button>
              <button class="tb-button tb-ml-5-px" :class="item.modelVisible ? 'bg-info' : 'bg-danger'"
                @click="tableInteractive(item, index, 'active')">
                <span class="text-white">Active</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Modal -->
    <b-modal v-model="addModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create Car Brand</a>
          <hr>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <label>Model Name</label>
              <input type="text" class="form-control" name="modelName" v-model="addObject.modelName">
            </div>
            <div class="col-12 mt-1">
              <label>Model Acceleration</label>
              <input type="text" class="form-control" name="modelAcceleration" v-model="addObject.modelAcceleration">
            </div>
            <div class="col-12 mt-1">
              <label>Model Durabilities</label>
              <input type="text" class="form-control" name="modelBrake" v-model="addObject.modelBrake" disabled>
            </div>
            <div class="col-12 mt-1">
              <label>Model Grip</label>
              <input type="text" class="form-control" name="modelGrip" v-model="addObject.modelGrip">
            </div>
            <div class="col-12 mt-1">
              <label>Model Top Speed</label>
              <input type="text" class="form-control" name="modelTopSpeed" v-model="addObject.modelTopSpeed">
            </div>
            <div class="col-12 mt-1">
              <label>Model Stock Price (ÖRN: 0.14)</label>
              <input type="text" class="form-control" name="modelStockPrice" v-model="addObject.modelStockPrice" disabled>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <label>Model ENUM (Develeoper'e Sorunuz!)</label>
              <input type="text" class="form-control" name="modelStockPrice" v-model="addObject.modelEnum">
            </div>
            <!--
            <div class="col-12 mt-1">
              <label>Model Image</label>
              <b-form-file
                  @change="imagePreview($event, 'add')"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
              />
            </div>
            -->
            <div v-if="addImageView" class="col-12 mt-2 px-4">
              <img class="img-fluid tb-radius-5 shadow" :src="addImageView" alt="">
              <button class="btn btn-block btn-danger btn-sm mt-1" @click="deleteImage('add')">Delete</button>
            </div>
            <div class="col-12 mt-2">
              <b-form-checkbox checked="true" class="custom-control-success" v-model="addObject.modelVisible"
                name="brandVisible" switch>Model Active
              </b-form-checkbox>
              <label>Model, Active ise tüm kullanıcılarda görünecektir. Pasif durumda ise Store'de
                gözükmeyecektir.</label>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!addObject.modelName" @click="saveData('create')">
            Create & Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal v-model="editModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create Car Brand</a>
          <hr>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <label>Model Name</label>
              <input type="text" class="form-control" name="modelName" v-model="editObject.modelName">
            </div>
            <div class="col-12 mt-1">
              <label>Model Acceleration</label>
              <input type="text" class="form-control" name="modelAcceleration" v-model="editObject.modelAcceleration">
            </div>
            <div class="col-12 mt-1">
              <label>Model Durabilities</label>
              <input type="text" class="form-control" name="modelBrake" v-model="editObject.modelBrake" disabled>
            </div>
            <div class="col-12 mt-1">
              <label>Model Grip</label>
              <input type="text" class="form-control" name="modelGrip" v-model="editObject.modelGrip">
            </div>
            <div class="col-12 mt-1">
              <label>Model Top Speed</label>
              <input type="text" class="form-control" name="modelTopSpeed" v-model="editObject.modelTopSpeed">
            </div>
            <div class="col-12 mt-1">
              <label>Model Stock Price (ÖRN: 0.14)</label>
              <input type="text" class="form-control" name="modelStockPrice" v-model="editObject.modelStockPrice" disabled>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <!--
            <div class="col-12">
              <label>Model Image</label>
              <b-form-file
                  @change="imagePreview($event, 'edit')"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
              />
            </div>
            -->
            <div v-if="editImageView" class="col-12 mt-2 px-4">
              <img class="img-fluid tb-radius-5 shadow" :src="editImageView" alt="">
              <button class="btn btn-block btn-danger btn-sm mt-1" @click="deleteImage('edit')">Delete</button>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!editObject.modelName"
            @click="saveData('update')">
            Update & Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- Media Modal -->
    <b-modal v-model="mediaModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <img :src="viewMedia" class="w-100 img-fluid" alt="">
        </div>
      </div>
    </b-modal>

  </section>
</template>
